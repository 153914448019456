// const apiBaseUrl = "http://10.194.182.110:5000/api";
// const mediaBaseUrl = "http://10.194.182.110:5000/media";
// const apiBaseUrl = "http://localhost:5000/api";
// const mediaBaseUrl = "http://localhost:5000/media";
const apiBaseUrl = "https://pattisewa.org/api";
const mediaBaseUrl = "https://pattisewa.org/media";

const apiEndpoints = {
  user: {
    login: `${apiBaseUrl}/user/sign-in`,
    logout: `${apiBaseUrl}/user/sign-out`,
    refreshToken: `${apiBaseUrl}/user/request-new-access-token`,
    verifyAccountSetupToken: `${apiBaseUrl}/user/verify-setup-account-token`,
    performAccountSetup: `${apiBaseUrl}/user/setup-account`,
    requestResetPassword: `${apiBaseUrl}/user/request-reset-password`,
    verifyResetPasswordToken: `${apiBaseUrl}/user/verify-reset-password-token`,
    performResetPassword: `${apiBaseUrl}/user/reset-password`,
  },
  patient: {
    addNew: `${apiBaseUrl}/patients/add`,
    updateImage: `${apiBaseUrl}/patients/update-image?docId={{docId}}`,
    update: `${apiBaseUrl}/patients/update?docId={{docId}}`,
    delete: `${apiBaseUrl}/patients/delete?docId={{docId}}`,
    getDetail: `${apiBaseUrl}/patients/get-detail?docId={{docId}}`,
    search: `${apiBaseUrl}/patients/search?searchQuery={{searchQuery}}`,
    getAllUpdatedSince: `${apiBaseUrl}/patients/get-all-updated-since?timestamp={{timestamp}}`,
  },
  patientDocument: {
    addNew: `${apiBaseUrl}/patient-documents/add`,
    updateFile: `${apiBaseUrl}/patient-documents/update-file?docId={{docId}}`,
    update: `${apiBaseUrl}/patient-documents/update?docId={{docId}}`,
    delete: `${apiBaseUrl}/patient-documents/delete?docId={{docId}}`,
    getDetail: `${apiBaseUrl}/patient-documents/get?docId={{docId}}`,
    getAllForPatient: `${apiBaseUrl}/patient-documents/get-all-for-patient?patientId={{patientId}}`,
  },
  point: {
    getAll: `${apiBaseUrl}/points/get-all`,
  },
  patientLog: {
    addNew: `${apiBaseUrl}/patient-logs/add`,
    addImage: `${apiBaseUrl}/patient-logs/add-image?docId={{docId}}`,
    deleteImage: `${apiBaseUrl}/patient-logs/delete-image?docId={{docId}}&imageId={{imageId}}`,
    reorderImages: `${apiBaseUrl}/patient-logs/reorder-images?docId={{docId}}`,
    updateImageNotes: `${apiBaseUrl}/patient-logs/update-image-notes?docId={{docId}}&imageId={{imageId}}`,
    update: `${apiBaseUrl}/patient-logs/update?docId={{docId}}`,
    delete: `${apiBaseUrl}/patient-logs/delete?docId={{docId}}`,
    getDetail: `${apiBaseUrl}/patient-logs/get-detail?docId={{docId}}`,
    getLatestLogForPatient: `${apiBaseUrl}/patient-logs/get-latest-for-patient?patientId={{patientId}}`,
    search: `${apiBaseUrl}/patient-logs/search?`, // + `patientId={{patientId}}&pointId={{pointId}}&startTimestamp={{startTimestamp}}&endTimestamp={{endTimestamp}}`
  },
};

export default apiEndpoints;
export { apiBaseUrl, mediaBaseUrl };
